.root{
  background-color: rgb(243, 243, 243);
  border:1px solid #ccc;
  display:block;
  width:fit-content;
  width:200px;
  border-radius: .3rem;
  padding:.5rem;
  position: relative;

}

.totalHours{
  position: absolute;
  right:.5rem;
}

.hours{
  display:flex;
  flex-direction:row;
  gap:.1rem;


}
.hour{
  position: relative;
  --width:1;
  width:calc(var(--width) * 100%);
  transition: width 0.5s;

  overflow: hidden;
  border-radius: .3rem;
  padding:.1rem;
  padding-bottom: 1.3rem;
}
.hour.selected{
  background-color: rgba(var(--hours-vertretung1-rgb),1) !important;
  cursor: pointer;
}

.hour>*{
  pointer-events: none;
}
.dates{
  font-size: 80%;
  position: absolute;
  bottom:.1rem;
  white-space: nowrap;
}

.root :global(.leitung){
  background-color: rgba(var(--hours-leitung-rgb), 1);
}
.root :global(.fachkraft){
  background-color: rgba(var(--hours-fachkraft-rgb), 1);
}

.root :global(.inklusion){
  background-color: rgba(var(--hours-inklusion-rgb), 1);
}

.root :global(.vertretung){
  background-color: rgba(var(--hours-vertretung-rgb), 0.5);
}


.substitutedHours{
  position: absolute;
  inset:0;
  display:flex;
  flex-direction: row;
  gap:1px;
}

.substitutedHour{
  position: relative;
  --width:1;
  height:100%;
  width:calc(var(--width) * 100%);
  transition: width 0.5s;
  background-color: rgba(255,255,255, 0.5);
  pointer-events: all;
}

.substitutedHour.selected{
  background-color: rgba(255,255,255, 0.8);
  border:5px solid rgba(var(--hours-vertretung1-rgb), 1)
}

.actions{
  margin-top:1rem;
  display:flex;
  flex-direction: row-reverse;
}

.editBtn{
  color:var(--bs-primary);
  cursor: pointer;
}

.root, .root *{
  user-select: none;
  cursor: pointer;
}