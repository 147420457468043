

body{
  --orange-rgb:230, 96, 37;
  --green-rgb:49, 161, 63;
  --hours-fachkraft-rgb: 49, 161, 63;
  --hours-inklusion-rgb: 230, 96, 37;
  --hours-leitung-rgb: 150, 150, 150;
  --hours-vertretung-rgb: 86, 143, 225;
  --hours-vertretung1-rgb: 66, 123, 205;

  /*230, 96, 37
  49, 161, 63
  247, 159, 0
  */
}

*{
  box-sizing: border-box;
}
