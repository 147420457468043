.root{

  position: fixed;
  inset: 0;

  display:flex;
  align-items: center;
  justify-content: center;
}

.bg{
  position: absolute;
  inset:0;
  background-color: rgba(0,0,0,0.7);
}
.content{
  position: absolute;
  padding:1rem;
  min-width: 50%;
  min-height: 50%;
  max-height: 90%;

  background-color: rgba(255,255,255,1);
  display:flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}
.header{
  position: relative;
  color:#ccc
}
.body{
  flex: 1 1 auto;
  overflow-y: scroll;
  padding:.5rem;
}
.footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.root :global(.close){
  position: absolute;
  right:0rem;
}


.hour{
  display:flex;
  gap:1rem;
  margin-bottom: 1rem;;
}
.root :global(.form-control){
  width:unset;
}