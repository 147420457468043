.App {
  padding:1rem;
}
.employees{
  margin-top:1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}
.App .addEmployeeBtn{

}


.timeline{
  position: relative;
  width:100%;
  min-height:5rem;
  background-color: rgba(240, 240, 240, 0.1);
  border:1px solid rgba(0,0,0,0.1);
  margin:0.5rem 0;

}

.thumb{
  position: absolute;

  width:30px;
  height: 100%;
  border: 2px solid rgba(var(--orange-rgb), .2);
  background-color: rgba(var(--orange-rgb), .3);
  cursor: pointer;
}

.steps{
  position: absolute;
  inset:0;
  right:50px
}
.step{
  position: absolute;
  border-radius: 50%;
  width:5px;
  height: 5px;
  background-color: silver;
}


.header{
  display:flex;
  justify-content: space-between;
  align-items: flex-start;
  gap:1rem;
}

.headerRight{
  flex: 1 1 auto;
}

.actionBar{
  display: flex;
  gap:10px;
  justify-content: flex-end;

}

.hourSums{
  font-size: 90%;
  width: 25ch;
}


.footer{
  position: absolute;
  bottom: 0;
  width:calc(100% - 2rem);
  text-align: center;

  font-size: 90%;
}

.App .footer a{
  color:rgba(var(--orange-rgb), 1);
  text-decoration: none;
}